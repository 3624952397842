import React, {forwardRef} from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { map } from "lodash";
import bditoffical from 'assets/images/bditoffical.png';

const PrintableInvoice  = forwardRef((props, ref) => {
    const rowStyle = {
      display: 'flex',
      flexWrap: 'nowrap'
    };
    const standartId = (id) => {
      const str_id = id?.toString(); // Convertir l'ID en chaîne de caractères
      if (str_id?.length === 1) {
        return `Nº 0000${str_id}`;
      } else if (str_id?.length === 2) {
        return `Nº 000${str_id}`;
      } else if (str_id?.length === 3) {
        return `Nº 00${str_id}`;
      } else if (str_id?.length === 4) {
        return `Nº 0${str_id}`;
      } else if (str_id?.length === 5) {
        return `Nº ${str_id}`;
      }
      // Retourner str_id dans le cas par défaut pour les ID qui ne sont pas de longueur 1 à 5
      return `Nº ${str_id}`;
    }
    return(
        <div ref={ref}>

        <Row>
        <Col lg="12">
          <Card>
            <CardBody className="mx-2">
              <div className="invoice-title">
                <div className="float-end">

                <h3 className="float-end font-size-50">
                  Devis {standartId(props?.isFacture)}
                </h3><br/>
                <span className="float-end">Dakar, le {props?.creationDate ? new Date(props?.creationDate).toLocaleDateString() : null}</span>
                </div>
                <div className="auth-logo mb-2">
                  
                <img src={bditoffical} alt="logo-dark" className="logo-dark-element" height="110" />
                <img src={bditoffical} alt="logo-light" className="logo-light-element" height="110" />
                </div>
              </div>
              <hr />
              <Row>
                <Col sm="6">
                  <address>
                    <strong>BD INT-TRADING</strong>
                    <br />
                    <span>Route de Saly, Mbour, Dakar</span>
                    <br />
                    <span>+221 77 611 04 50</span>
                    <br />
                    <span>info@bdintrading.com</span>
                    <br />
                  </address>
                </Col>
                <Col sm="6" className="text-sm-end">
                  <address>
                    <strong>DESTINATAIRE </strong>
                    <br />
                    <span>{props?.client?.fullname}</span>
                    <br />
                    <span>Dakar Hann</span>
                    <br />
                    <span>{props?.client?.phone}</span>
                    <br />
                    <span>{props?.client?.email}</span>
                    <br />
                  </address>
                </Col>
              </Row>
              {/* <div className="py-2 mt-3">
                <h3 className="font-size-15 fw-bold">Produits</h3>
              </div> */}
              <div className="table-responsive mt-3">
                <Table className="table-nowrap">
                  <thead>
                    <tr>
                      <th>Article</th>
                      <th>Quantité</th>
                      <th>Unité</th>
                      <th>Price unitaire</th>
                      <th className="text-end">Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      props?.articles,
                      (item, key) => (
                        <tr key={key}>
                          <td>{item?.product.title}</td>
                          <th>{item?.quantity? (item?.quantity).toLocaleString() : 0}</th>
                          <th>{item?.product?.unity?.libelle}</th>
                          <td>{item?.price ? (item?.price).toLocaleString() : 0} XOF</td>
                          <td className="text-end">{(item?.price * item?.quantity).toLocaleString()} XOF</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td colSpan="4" className="border-0 text-end">
                        <strong>Total HT</strong>
                      </td>
                      <td className="border-0 text-end">
                      {props?.total? (props?.total).toLocaleString() : 0} XOF
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="border-0 text-end">
                        <strong>TVA (18%)</strong>
                      </td>
                      <td className="border-0 text-end">
                      {props?.total? (props?.total * 0.18).toLocaleString() : 0} XOF
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4" className="border-0 text-end">
                        <strong>Total TTC</strong>
                      </td>
                      <td className="border-0 text-end">
                        {props?.total? (props?.total + (props?.total * 0.18)).toLocaleString() : 0} XOF
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="d-print-none">
                <div className="float-end">
                  <Link
                    to="#"
                    onClick={props?.printInvoice}
                    className="btn btn-success  me-2"
                  >
                    <i className="fa fa-print" />
                  </Link>
                  <Link to="#" className="btn btn-primary w-md ">
                    Convertir en facture
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        </Row>
        </div>
    )
});

PrintableInvoice.displayName = "PrintableInvoice";
export default PrintableInvoice;