import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"


import { post } from "helpers/apiServices";
import {
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { toast } from "react-toastify";

const Rapport = () => {
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [uncompressedData, setUncompressedData] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleDateFrom = async (e) =>{
    e.preventDefault();
    setDateFrom(e.target.value);
  }

  const handleDateTo = async (e) =>{
    e.preventDefault();
    setDateTo(e.target.value)
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    
    try {
      const response = await post(
        'products/rapports/',
        {
          start_date: dateFrom,
          end_date: dateTo,
        }
      );
      console.log(response.data)
    } catch (error) {
      setModal(false);
      toast.error("Something went wrong. Please try again")
    }
  }

  return (
    <React.Fragment>
    <Modal isOpen={modal} toggle={toggle} className="col-10 modal-lg">
      <ModalHeader toggle={toggle} tag="h4">
        Exporting data. Please wait!
      </ModalHeader>
      <ModalBody>
          <Row>
            <Col>
              <div className="text-center">
                <div className="spinner-border text-primary" role="status" style={{ width: '8rem', height: '8rem' }}>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </Col>
          </Row>
      </ModalBody>
    </Modal>
      <Card className="overflow-hidden">
        
        <form onSubmit={handleSubmit}>
        <div className="bg-primary-subtle">
          <Row>
            <Col xs="4" className="col-12">

              <div className=" p-3">
                <h5 className="">Rapport sur un intervalle de temps</h5>
                <div className="d-flex justify-content-between">
                  <div className="col-5">
                        <input
                          className="form-control"
                          type="date"
                          id="example-datetime-local-input"
                          value={dateFrom}
                          onChange={handleDateFrom}
                          required
                        />
                  </div>
                  <div className="col-5">
                        <input
                          className="form-control"
                          type="date"
                          id="example-datetime-local-input"
                          value={dateTo}
                          onChange={handleDateTo}
                          required
                        />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0 col-12">
          <Row>

            <Col className="col-12">
              <div className="pt-1 text-center">
                <div className="mt-4">
                  <button
                    className="btn btn-primary  btn-md"
                    type="submit"
                  >
                    Consulter <i className="mdi mdi-arrow-right ms-1"></i>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        </form>
      </Card>
    </React.Fragment>
  )
}
export default Rapport
