import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
  Spinner
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { get, post } from "helpers/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


const NewInvoice = () => {
  document.title = "Créer facture | BD International Trading";
  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState();
  const [formRows, setFormRows] = useState([{ id: 1, product: "", quantity: 1}]);
  const [creating, setCreating] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await get('products/');
        let allProducts = response?.data;
        if(response.status === 200){    
          let objectTab = allProducts.map((product) => ({
            value: product.id,
            label: product.title
          }));
          setProducts(objectTab);
        }
      } catch (error) {
        console.error('Error getting products:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await get('clients/');
        let allClients = response?.data;
        if(response.status === 200){         
          let objectTab = allClients.map((client) => ({
            value: client.id,
            label: `${client?.fullname}`
          }));
          setClients(objectTab);
        }
      } catch (error) {
        console.error('Error getting clients:', error);
      }
    };
    fetchData();
  }, []);

  const handleProductChange = (value, index) => {
    const updatedFormRows = [...formRows];
    updatedFormRows[index].product = value;
    setFormRows(updatedFormRows);
    const newProductList = products.filter(row => row.value !== value?.value);
    setProducts(newProductList)
  };

  const handleQuantityChange = (value, index) => {
    const updatedFormRows = [...formRows];
    updatedFormRows[index].quantity = value;
    setFormRows(updatedFormRows);
  };


  const handleClientChange = (selectedOption) => {
    setClientId(selectedOption.value);
  }

  const handleSubmit = async (e) => {
    setCreating(true)
    e.preventDefault();
    let requestTab = []
    formRows.forEach((ligne) =>{
      requestTab.push({
        product_id: ligne['product'].value,
        quantity: ligne['quantity'],
      })
    });
    const obj = {
      client_id: clientId,
      products: requestTab
    }
    try {
      const response = await post(
        'bills/',
        obj
      );
      const responseData = await response.data;
      if(response.status === 201){
        navigate(`/facture/${responseData?.data?.id}`)
      } 
    
    } catch (error) {
      setCreating(false)
      toast.error(error?.response?.data?.detail)
      console.error('Error fetching data:', error);
      
    }
  }

  const onAddFormRow = () => {
    const updatedFormRows = [...formRows];
    updatedFormRows.push({ id: updatedFormRows.length + 1, product: "", quantity: 1});
    setFormRows(updatedFormRows);
  };

  const onDeleteFormRow = formRowTD => {
    if (formRowTD.id !== 1) {
      const updatedFormRows = formRows.filter(row => row.id !== formRowTD.id);
      
      setFormRows(updatedFormRows);
      if(formRowTD.product){

        const newProductsList = [...products];
        newProductsList.push(formRowTD.product);
        setProducts(newProductsList);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Tableau de bord" url={"/"} breadcrumbItem="Facturation" />
          <Row>
            <Col xs={12} style={{ height: '100vh', overflowY: 'auto' }}>
            {creating ? (
              
            <Spinner color="primary" className='position-absolute top-50 start-50 mt-4' />
            ):(
              <Card>
                <CardBody>
                  <h6 className="mb-4 card-title">Créer une nouvelle facture</h6>
                  <Form className="repeater" encType="multipart/form-data" onSubmit={handleSubmit}>
                    <div>
                      <Row>
                        <Col className="mb-3 col-3">
                          <Label className="control-label">Choisir un client</Label>
                          <Select
                            className="select2"
                            placeholder="Selectionner client"
                            options={clients}
                            onChange={handleClientChange}
                            required
                          />
                        </Col>
                      </Row>
                      {formRows.map((formRow, index) => (
                        <Row key={formRow.id} className="d-flex justify-content-between">
                          <Col className="mb-3 col-3">
                            <Label className="control-label">Produit</Label>
                            <Select
                              className="select2"
                              placeholder="Choisir produit"
                              options={products}
                              value={formRow.product}
                              onChange={(selectedOption) => handleProductChange(selectedOption, index)}
                              required
                            />
                          </Col>
                          <Col className="col-3">
                            <label htmlFor="subject">Quantité/Nombre</label>
                            <input
                              type="number"
                              id="subject"
                              className="form-control"
                              placeholder="Saisir la quantité"
                              value={formRow.quantity}
                              onChange={(e) => handleQuantityChange(parseInt(e.target.value), index)}
                              min={1}
                              required
                            />
                          </Col>
                          <Col lg={2} className="align-self-center">
                            <div className="d-grid">
                              <input
                                type="button"
                                className="btn btn-danger"
                                value="Supprimer colonne"
                                onClick={() => onDeleteFormRow(formRow)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                    <div className="d-flex justify-content-between">
                      <input
                        type="button"
                        className="btn btn-warning mt-3 mt-lg-0"
                        value="Ajouter colonne"
                        onClick={() => onAddFormRow()}
                      />
                      <Button type="submit" color="primary">
                        Soumettre
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

            )

            }
              
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewInvoice;
