import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table, CardTitle
} from "reactstrap";

//import action

import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import Chartapex from "./DashboardChartapex";
import WelcomeComp from "./WelcomeComp";
import DashboardRadialChart from "./DashboardRadialChart";
import { get } from "helpers/apiServices";
import { Link } from "react-router-dom";
import Rapport from "./Rapport";
import Humankpi from "./Humankpi";


const Dashboard = props => {
  document.title = "Tableau de bord | BD International Trading";
  const [billsList, setBillsList] = useState([]);
  const [productsData, setProductsData] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('bills/');
        setBillsList(response?.data?.results);
      } catch (error) {
        console.error('Error while getting projects: ', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('products/dashboard/');
        setProductsData(response?.data);
      } catch (error) {
        console.error('Error while getting projects: ', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Informations générales")}
            breadcrumbItem={props.t("Tableau de bord")}
          />

          <Row>
            <Col xl="4">
              <Rapport/>
              <WelcomeComp productsData={productsData}/>
              <Humankpi productsData={productsData}/>
              {/* <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Entrées/Sorties globales</CardTitle>
                    <DashboardRadialChart 
                      dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning"]'
                      productsData={productsData}
                    />
                  </CardBody>
                </Card>
              </Col> */}
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                
                <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <Link to="/products/filtred/stock">
                              <p className="text-muted fw-medium">
                              En Stock
                              </p>
                            </Link>
                            <h4 className="mb-0">{productsData?.stock ? productsData?.stock : 0}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className="bx bx-archive-in font-size-24"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>
                
                <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <Link to="/products/filtred/faible">
                              <p className="text-muted fw-medium">
                              Stock faible
                              </p>
                            </Link>
                            <h4 className="mb-0">{productsData?.faible? productsData?.faible : 0}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className="bx bx-copy-alt font-size-24"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>
                
                <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <Link to="/products/filtred/epuise">
                              <p className="text-muted fw-medium">
                              Epuisés
                              </p>
                            </Link>
                            <h4 className="mb-0">{productsData?.epuise? productsData?.epuise : 0}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className="bx bx-purchase-tag-alt font-size-24"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <Chartapex
                   ventes={productsData?.ventes_par_mois} 
                   achats={productsData?.produits_par_mois} 
                   mois={productsData?.mois}
                   total_produits={productsData?.total}
                   total_ventes={productsData?.ventes}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col lg="12">
              <LatestTranaction bills={billsList}/>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
