import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { map } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

// TableContainer

import { Pdate, Ddate, Name, Idno, Budget } from "./CryptoCol";

import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";


// import charts
import { getUserProfile } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "helpers/apiServices";


const UsersProfile = props => {

  //meta title
  document.title = "Profile | BD International Trading";
  const [userInformations, setUserInformations] = useState()
  const [bills, setBills] = useState([])
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`users/${id}`);
        const responseData = response.data;
        setUserInformations(responseData);
        setBills(responseData?.bills);

      } catch (error) {
        console.error('Error getting products:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, [])
  const { userProfile, onGetUserProfile } = props;
  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    {
      title: "Completed Projects",
      iconClass: "bx-check-circle",
      text: "125",
    },
    { title: "Pending Projects", iconClass: "bx-hourglass", text: "12" },
    { title: "Total Revenue", iconClass: "bx-package", text: "$36,524" },
  ]);

  useEffect(() => {
    onGetUserProfile();
  }, [onGetUserProfile]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Idno {...cellProps} />;
        },
      },
      {
        Header: "Client",
        accessor: "client.fullname",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Date",
        accessor: "created_date",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />;
        },
      },
      {
        Header: "Produits",
        accessor: "products",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Ddate {...cellProps} />;
        },
      },
      {
        Header: "Total TTC",
        accessor: "total",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Budget {...cellProps} />;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Liste utilisateurs" breadcrumbItem="Profile" url={`/employes`}/>

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary"></h5>
                        <p></p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="6">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={userInformations?.avatar}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {userInformations?.first_name} {userInformations?.last_name}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                      {userInformations?.role?.libelle}
                      </p>
                    </Col>

                    <Col sm={6}>
                      <div className="pt-4">
                        <Row>
                        {userInformations?.status? (

                          <Col xs="6">
                            <h5 className="text-success font-size-15">
                              Actif
                            </h5>
                            <p className="text-muted mb-0">Compte</p>
                          </Col>
                        ) : (
                          <Col xs="6">
                            <h5 className="text-danger font-size-15">
                            Inactif  
                            </h5>
                            <p className="text-muted mb-0">Compte</p>
                          </Col>
                        )}
                        </Row>
                      </div>
                    </Col> 
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informations personnelles</CardTitle>
                  <p className="text-muted mb-4">
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Nom complet :</th>
                          <td>{userInformations?.first_name} {userInformations?.last_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Téléphone :</th>
                          <td>{userInformations?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{userInformations?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Adresse :</th>
                          <td>{userInformations?.address}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                  <MiniCards
                    title={'Ventes effectuées'}
                    text={userInformations?.bills? (userInformations?.bills).length: 0}
                    iconClass={`bx-check-circle`}
                  />
                  <MiniCards
                    title={'Devis faits'}
                    text={userInformations?.devis? (userInformations?.devis).length: 0}
                    iconClass={`bx-check-circle`}
                  />
                  <MiniCards
                    title={'xxxx xxx'}
                    text={'0'}
                    iconClass={`bx-check-circle`}
                  />
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Ventes effectuées par {userInformations?.first_name} {userInformations?.last_name}</CardTitle>

                  <TableContainer
                    columns={columns}
                    data={bills || []}
                    isGlobalFilter={false}
                    customPageSize={5}
                    customPageSizeOptions={true}
                    isPagination={true}
                    tableClass="align-middle table-nowrap table-check"
                    theadClass="table-light"
                    pagination="pagination pagination-rounded justify-content-end"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

UsersProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersProfile));
