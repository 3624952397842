import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import UsersTableContainer from "./UsersTableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Email, Poste, Phone, Status, Role } from "./UserlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "./DeleteModal";


//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { get, post, put, remove } from "helpers/apiServices";
import { deleteCustomer } from "store/actions";

const UsersList = props => {

  //meta title
  document.title = "Utilisateurs | BD International Trading";
  const [allUsers, setAllUsers] = useState([])

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [role, setRole] = useState();
  const [userId, setUserId] = useState();
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: (contact && contact.first_name) || "",
      last_name: (contact && contact.last_name) || "",
      phone: (contact && contact.phone) || "",
      email: (contact && contact.email) || "",
      address: (contact && contact.address) || "",
      cni: (contact && contact.cni) || "",
      role: (contact && contact.role) || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Veuillez renseigner le prénom de l'utilisateur"),
      last_name: Yup.string().required("Veuillez renseigner le nom de l'utilisateur"),
      phone: Yup.string().required("Veuillez renseigner le numéro de téléphone"),
      cni: Yup.string(),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
      address: Yup.string().required("Veuillez renseigner l'adresse"),
      role: Yup.number().required("Veuillez sélectionner le rôle de l'employé"),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        console.log("Please: ",values)
        const updateUser = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          cni: values.cni,
          address: values.address,
          role: values.role,
        };
        // update user
        
        
      try {
        const response = await put(`users/${userId}`, updateUser);
        console.log(response);
        if(response.status === 200){
          const response1 = await get('users/');
          setAllUsers(response1?.data);
          toast.success("Utilisateur(rice) mises à jour avec avec succès")
        }
        
      } catch (error) {
        if(error?.response?.status === 403 || error?.response?.status === 404){
      
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Une erreur est survenue lors de la création de l\'utilisateur')
          console.error('Error', error);
        }
        // Gérer l'erreur ici
      }
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          first_name: values["first_name"],
          last_name: values["last_name"],
          phone: values["phone"],
          email: values["email"],
          cni: values["cni"],
          address: values["address"],
          role: values["role"],
        };
        // save new user
        
      try {
        const response = await post('users/', newUser);
        console.log(response);
        if(response.status === 201){
          const response1 = await get('users/');
          setAllUsers(response1?.data);
          toast.success("Utilisateur(rice) ajouté(e) avec succès")
        }
        
      } catch (error) {
        if(error?.response?.status === 403 || error?.response?.status === 404){
          
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Une erreur est survenue lors de la création de l\'utilisateur')
          console.error('Error', error);
        }
        // Gérer l'erreur ici
      }
        validation.resetForm();
      }
      toggle();
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('users/');
        setAllUsers(response?.data);
      } catch (error) {
        console.error('Error while getting uers: ', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('users/roles/');
        setRoles(response?.data);
      } catch (error) {
        console.error('Error while getting uers: ', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);


  const selectContactsState = (state) => state.contacts;
  const ContactsProperties = createSelector(
    selectContactsState,
    (Contacts) => ({
      users: Contacts.users,
      loading: Contacts.loading
    })
  );

  const {
    users, loading
  } = useSelector(ContactsProperties);

  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.avatar ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                {cellProps.first_name.charAt(0)} {cellProps.last_name.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={cellProps.avatar}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Nom",
        accessor: "prenom",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
            <Link className="text-dark" to={`/users-profile/${cellProps.row.original.id}`}>{cellProps.row.original.first_name} {cellProps.row.original.last_name}</Link>
            </>
          )
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Role",
        accessor: "role.libelle",
        filterable: true,
        Cell: cellProps => {
          return <Role {...cellProps} />;
        },
      },
      {
        Header: "Téléphone",
        accessor: "phone",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Phone {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Status compte",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setUserId(user.id);

    setContact({
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: user.email,
      address: user.address,
      cni: user.cni,
      role: user.role.id,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setUserId(users.id);
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    if (contact && contact.id) {
      
        try {
          const response = await remove(`users/${userId}`);
          console.log(response);
          if(response.status === 204){
            const response1 = await get('users/');
            setAllUsers(response1?.data);
            toast.success("Utilisateur(rice) Supprimé avec avec avec succès")
          }
          
        } catch (error) {
          if(error?.response?.status === 403 || error?.response?.status === 404){
            
            toast.error(error?.response?.data?.details)
          }else{
            toast.error('Une erreur est survenue lors de la suppression de l\'utilisateur')
            console.error('Error', error);
          }
          // Gérer l'erreur ici
        }
    }
    console.log("user to deleted: ", contact)
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setContact(null)
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        user={deleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
        message={`Voulez-vous vraiment supprimer l'utilisateur ${contact?.first_name} ${contact?.last_name}`}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs  title="Tableau de bord" url={"/"} breadcrumbItem="Liste des employés" />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <UsersTableContainer
                        isPagination={true}
                        columns={columns}
                        data={allUsers}
                        isGlobalFilter={true}
                        isShowingPageLength={true}
                        isAddUserList={true}
                        iscustomPageSizeOptions={true}
                        handleUserClick={handleUserClicks}
                        customPageSize={10}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination pagination-rounded justify-content-end mt-4"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }

            <Modal isOpen={modal} toggle={toggle} className="col-10 modal-lg">
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Editer utilisateur" : "Nouvel utilisateur"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col className="col-12 ">
                    <div className="d-flex justify-content-around">

                      <div className="mb-3 col-5">
                        <Label className="form-label">Prénom</Label>
                        <Input
                          name="first_name"
                          type="text"
                          placeholder="Saisir le prénom"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                              validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                          validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3 col-5">
                        <Label className="form-label">Nom</Label>
                        <Input
                          name="last_name"
                          type="text"
                          placeholder="Saisir le nom"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                              validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                          validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                      <div className="d-flex justify-content-around">

                        <div className="mb-3 col-5">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            label="Email"
                            type="email"
                            placeholder="Saisir Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-5">
                          <Label className="form-label">Téléphone</Label>
                          <Input
                            name="phone"
                            label="Designation"
                            placeholder="Saisir numéro de téléphone"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={
                              validation.touched.phone &&
                                validation.errors.phone
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone &&
                            validation.errors.phone ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-around">
                        <div className="mb-3 col-5">
                          <Label className="form-label">Adresse</Label>
                          <Input
                            name="address"
                            label="Designation"
                            placeholder="Saisir l'adresse de l'employé"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                                validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                            validation.errors.address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-5">
                          <Label className="form-label">Numéro de la pièce d'identification</Label>
                          <Input
                            name="cni"
                            label="Designation"
                            placeholder="Saisir le numéro de la pièce d'identification"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.cni || ""}
                            invalid={
                              validation.touched.cni &&
                                validation.errors.cni
                                ? true
                                : false
                            }
                          />
                          {validation.touched.cni &&
                            validation.errors.cni ? (
                            <FormFeedback type="invalid">
                              {validation.errors.cni}
                            </FormFeedback>
                          ) : null}
                        </div>

                      </div>
                      <div className="d-flex justify-content-around">

                      <div className="mb-3 col-11">
                        <Label className="form-label">Rôle</Label>
                        <Input
                          type="select"
                          name="role"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || null}
                          invalid={
                            validation.touched.role &&
                              validation.errors.role
                              ? true
                              : false
                          }
                        >
                          <option value="" disabled selected hidden>Veuillez choisir un rôle</option>
                          {roles.map(role => {
                            return (
                              <option key={role.id} value={role.id}>
                                {role.libelle}
                              </option>
                            );
                          })}
                        </Input>
                        {validation.touched.role &&
                          validation.errors.role ? (
                          <FormFeedback type="invalid">
                            {validation.errors.role}
                          </FormFeedback>
                        ) : null}
                      </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary save-user"
                        >
                          Ajouter
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </Modal>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(UsersList);
