import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import ProductTableContainer from '../../components/Common/ProductTableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import { get, post, put as modify, remove } from "helpers/apiServices";


//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder
} from "store/actions";


import {
  ProductId,
  Title,
  CreatedDate,
  Price,
  Quantity,
  Sold
}
  from "./ProductCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import ProductsModal from "./ProductsModal";
import Select from "react-select";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
// toast
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router-dom";

const FiltredProducts = () => {

  const { infos } = useParams();
  console.log('infos: ', infos);
  //meta title
  document.title = "Produits | BD International Trading";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [extension, setExtension] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [selectedFournisseurs, setSelectedFournisseurs] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const units = [
    { value: "kg", label: "Kilogrammes" },
    { value: "l", label: "Litres" },
    { value: "m", label: "Mètres" },
    { value: "sacs", label: "Sacs" },
  ]



  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (product && product.title) || '',
      price: (product && product.price) || '',
      quantity: (product && product.quantity) || '',
      description: (product && product.description) || null
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Veuillez renseigner le titre"),
      price: Yup.number().required("Veuillez renseigner le prix"),
      quantity: Yup.string().required("Veuillez renseigner la quantité")
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateProduit = {
          title: values.title,
          description: values.description || null,
          quantity: values.quantity,
          price: values.price
        };
        // update order
        try {
          const response = await modify(`products/${product.id}`, updateProduit);
          if(response.status === 200){
          const allProduct = await get(`products/filtred/${infos}`);
          setProducts(allProduct.data);
          }
        } catch (error) {
          console.error('Error editing product:', error);
        }
        validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectEcommerceState = (state) => state.ecommerce;
  const EcommerceOrderProperties = createSelector(
    selectEcommerceState,
    (Ecommerce) => ({
      orders: Ecommerce.orders,
      loading: Ecommerce.loading
    })
  );

  const { orders, loading } = useSelector(EcommerceOrderProperties);

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders());
    }
  }, [dispatch, orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setIsEdit(false);
    }
  }, [orders]);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('fournisseurs/');
        if(response.status === 200){  
          let allFournisseurs = response?.data;     
          let objectTab = allFournisseurs.map((fournisseur) => ({
            value: fournisseur.id,
            label: fournisseur.fullname
          }));
          setFournisseurs(objectTab);
        }
      } catch (error) {
        console.error('Error while getting fournisseurs:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`products/filtred/${infos}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error getting products:', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setProduct(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setProduct(order);
    setDeleteModal(true);
    
  };

  const handleDeleteProduct = async (productToDelete) => {
    if (productToDelete && productToDelete?.id) {
      try {
        const response = await remove(`/products/${productToDelete?.id}`);
        setDeleteModal(false);
        if(response.status === 204){
          const allProduct = await get(`products/filtred/${infos}`);
          setProducts(allProduct.data);
          toast.success("Produit supprimé avec succès", { autoClose: 2000 });
        }
      } catch (error) {
        console.log("Erreur: ", error);
      }
    }
  };

  //delete
  const handleDetailsProduct = async (id) => {
    if (id) {
      
      try {
        const response = await get(`/products/${id}`);
        setDeleteModal(false);
        if(response.status === 200){
          setProduct(response.data);
        }
      } catch (error) {
        console.log("Erreur: ", error);
      }
    }
  };
  const handleOrderClicks = () => {
    setIsEdit(false);
    toggle();
  };
  
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
    const fournisseursTab = []
    selectedMulti.map(selectedfournisseur => {
      if(selectedfournisseur){
        fournisseursTab.push(selectedfournisseur?.value);
      }
    });
    setSelectedFournisseurs(fournisseursTab);
  }

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'Titre',
        accessor: 'title',
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      {
        Header: 'Date de création',
        accessor: 'created_date',
        filterable: true,
        Cell: (cellProps) => {
          return <CreatedDate {...cellProps} />;
        }
      },
      {
        Header: 'Prix',
        accessor: 'price',
        filterable: true,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        }
      },
      {
        Header: 'Disponibles',
        accessor: 'quantity',
        filterable: true,
        Cell: (cellProps) => {
          return <Quantity {...cellProps} />;
        }
      },
      {
        Header: 'Vendus',
        accessor: 'nbrAchat',
        Cell: (cellProps) => {
          return <Sold {...cellProps} />;
        }
      },
      {
        Header: 'Voir Détails',
        accessor: 'view',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={
                () => {
                  toggleViewModal();
                  const orderData = cellProps.row.original;
                  setProduct(orderData);
                  handleDetailsProduct(cellProps.row.original.id)
                }
              }
            >
              Détails produit
            </Button>);
        }
      },
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/products/edit/${cellProps.row.original.id}`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const productData = cellProps.row.original;
                  onClickDelete(productData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ProductsModal isOpen={modal1} toggle={toggleViewModal} product={product}/>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tableau de bord" url={"/"} breadcrumbItem="Liste produits" />
          {
            isLoading && products.length === 0 ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <ProductTableContainer
                        columns={columns}
                        data={products}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
          <Modal isOpen={modal} toggle={toggle} className="col-10 modal-lg">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Modifier Produit" : "Add Order"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                  <div className="d-flex justify-content-around">

                    <div className="mb-3 col-5">
                      <Label>Nom produit</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Insert Order Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
                        <div className="mb-3 col-5">
                          <Label htmlFor="price">Image du produit</Label>
                          <Input
                            id="price"
                            name="price"
                            type="file"
                            className="form-control"
                            placeholder="Image du produt"
                            accept="image/*"
                          />
                        </div>
                  </div>
                  <div className="d-flex justify-content-around">

                    <div className="mb-3 col-5">
                      <Label>Prix unitaire</Label>
                      <Input
                        name="price"
                        type="number"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.price || ""}
                        invalid={
                          validation.touched.price && validation.errors.price ? true : false
                        }
                      />
                      {validation.touched.price && validation.errors.price ? (
                        <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3 col-5">
                      <Label className="control-label">Unité de vente(kg/m/l)*</Label>
                      <Select
                        placeholder="Select"
                        options={units}
                        required
                      />
                    </div>

                  </div>
                  <div className="d-flex justify-content-around">
                    <div className="mb-3 col-5">
                      <Label>Quantité</Label>
                      <Input
                        name="quantity"
                        type="number"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quantity || ""}
                        invalid={
                          validation.touched.quantity && validation.errors.quantity ? true : false
                        }
                      />
                      {validation.touched.quantity && validation.errors.quantity ? (
                        <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3 col-5">
                      <Label>Prix unitaire</Label>
                      <Input
                        name="price"
                        type="number"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.price || ""}
                        invalid={
                          validation.touched.price && validation.errors.price ? true : false
                        }
                      />
                      {validation.touched.price && validation.errors.price ? (
                        <FormFeedback type="invalid">{validation.errors.price}</FormFeedback>
                      ) : null}
                    </div>

                  </div>
                  <div className="d-flex justify-content-around"></div>
                        <div className="mb-3">
                          <label className="control-label">
                            Fournisseurs
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            options={fournisseurs}
                            onChange={handleMulti}
                            className="select2-selection"
                          />
                        </div>
                    <div className="mb-3">
                      <Label>Description</Label>
                          <textarea
                            className="form-control"
                            id="description"
                            placeholder="Description du produit..."
                            rows="3"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                          ></textarea>
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Enregistrer
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
          
    <Modal size="md" isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={() => setDeleteModal(false)} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">{"Voulez vous supprimer ce produit? La suppression sera permenante."}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={() => handleDeleteProduct(product)}>Supprimer</button>
            <button type="button" className="btn btn-secondary" onClick={() => setDeleteModal(false)}>Fermer</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

FiltredProducts.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default FiltredProducts;