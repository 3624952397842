import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "./TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { get } from "helpers/apiServices";
import { toast } from "react-toastify";
import { remove } from "helpers/apiServices";

const DevisList = props => {

  //meta title
  document.title = "Devis | BD International Trading";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  // validation

  const selectContactsState = (state) => state.contacts;
  const ContactsProperties = createSelector(
    selectContactsState,
    (Contacts) => ({
      users: Contacts.users,
      loading: Contacts.loading
    })
  );

  const {
    users, loading
  } = useSelector(ContactsProperties);

  const [devis, setDevis] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(loading)
  const [estimation, setEstimation] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('estimations/');
        setDevis(response?.data);
      } catch (error) {
        console.error('Error while getting projects: ', error);
        // Gérer l'erreur ici
      }
    };
    fetchData();
  }, []);
  const standartId = (id) => {
    const str_id = id?.toString(); // Convertir l'ID en chaîne de caractères
    if (str_id?.length === 1) {
      return `0000${str_id}`;
    } else if (str_id?.length === 2) {
      return `000${str_id}`;
    } else if (str_id?.length === 3) {
      return `00${str_id}`;
    } else if (str_id?.length === 4) {
      return `0${str_id}`;
    } else if (str_id?.length === 5) {
      return `${str_id}`;
    }
    // Retourner str_id dans le cas par défaut pour les ID qui ne sont pas de longueur 1 à 5
    return str_id;
  }
  const handleDeleteDevis = async () => {
    
    if (estimation && estimation?.id) {
      try {
        const response = await remove(`/estimations/${estimation?.id}`);
        setDeleteModal(false);
        if(response.status === 204){
          const response = await get('estimations/');
          setDevis(response?.data);
          toast.success("Devis supprimé avec succès", { autoClose: 2000 });
        }
      } catch (error) {
        if(error?.response?.status === 403 || error?.response?.status === 404){
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Une erreur est survenue lors de la suppression du devis')
          console.error('Error', error);
        }
      }
    }
    setDeleteModal(false);
  };
  const columns = useMemo(
    () => [
      {
        Header: "#",
        // accessor: "id",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Link className="text-dark font-weight-bold" to={`/devis/${cellProps.row.original.id}`}>D#{standartId(cellProps.row.original.id)}</Link>
            </>
          )
        },
      },
      {
        Header: "Client",
        accessor: "client",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Link className="text-dark" to="#">{cellProps.row.original.client.fullname}</Link>
            </>
          )
        },
      },
      {
        Header: "Date",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Link className="text-dark" to="#">{
                cellProps.row.original.created_date? new Date(cellProps.row.original.created_date).toLocaleString('fr-fr') : ""
              }</Link>
            </>
          )
        },
      },
      {
        Header: "Montant HT",
        accessor: "total",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Link className="text-dark" to="#">{
                cellProps.row.original.total ? (cellProps.row.original.total).toLocaleString() : 0
              } XOF</Link>
            </>
          )
        },
      },
      {
        Header: "Montant TTC",
        accessor: "user.id",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {
                cellProps.row.original.total ? (parseInt(cellProps.row.original.total + (cellProps.row.original.total * 0.18))).toLocaleString() : 0
              } XOF
            </>
          )
        },
      },
      {
        Header: "Vendeur",
        accessor: "projects",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {cellProps.row.original.user.first_name + " " + cellProps.row.original.user.last_name}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const devisData = cellProps.row.original;
                  onClickDelete(devisData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = stuff => {
    setEstimation(stuff);
    setDeleteModal(true);
  };
  
  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDevis}
        onCloseClick={() => setDeleteModal(false)}
        message={"Voulez-vous vraiment supprimer ce devis!"}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tableau de bord" breadcrumbItem="Devis" url={'/'} />
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        isPagination={true}
                        columns={columns}
                        data={devis}
                        isGlobalFilter={true}
                        isShowingPageLength={true}
                        isAddUserList={true}
                        iscustomPageSizeOptions={true}
                        handleUserClick={handleUserClicks}
                        customPageSize={10}
                        tableClass="table align-middle table-nowrap table-hover"
                        theadClass="table-light"
                        paginationDiv="col-sm-12 col-md-7"
                        pagination="pagination pagination-rounded justify-content-end mt-4"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }

          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(DevisList);
