import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"

const Humankpi = (props) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary-subtle">
          <Row>
            <Col xs="12">
              <div className=" p-3">
                <h5 className="d-flex justify-content-between">
                  <div>Clients</div>
                  <div>Fournisseurs</div>
                  <div>Employés</div>
                </h5>
                <p></p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>

            <Col sm="12">
              <div className="pt-4">
                <Row>
                  <Col xs="4">
                    <h5 className="font-size-15">{props?.productsData?.clients ? props?.productsData?.clients : 0}</h5>
                    <p className="text-muted mb-0">Clients</p>
                  </Col>
                  <Col xs="4">
                    <h5 className="font-size-15">{props?.productsData?.fournisseurs ? props?.productsData?.fournisseurs : 0}</h5>
                    <p className="text-muted mb-0">Fournisseurs</p>
                  </Col>
                  <Col xs="4">
                    <h5 className="font-size-15">{props?.productsData?.employes ? props?.productsData?.employes : 0}</h5>
                    <p className="text-muted mb-0">Employés</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default Humankpi
