import axios from 'axios';

const BASE_URL = 'https://core.bdinternationaltrading.com/api/v1/';
//const BASE_URL = 'http://127.0.0.1:8000/api/v1/';

const api = axios.create({
  baseURL: BASE_URL
});

export const LoginService = (data) => {
  return new Promise((resolve, reject) => {
    try {
      api.post('authentication/login/', data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.error('Error creating data:', error);
          resolve(error);
        });
    } catch (error) {
      console.error('Error creating data:', error);
      reject(error);
    }
  });
};
