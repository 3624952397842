import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import axios from "axios";
import { post, logoutService } from "helpers/apiServices";
import { LoginService } from "helpers/loginServices";

const fireBaseBackend = getFirebaseBackend();


const loginAxios = async (formData) => {
  try {
    const response = await LoginService(
      'authentication/login/',
      formData
    );
    return response;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
  
}

function* loginUser({ payload: { user, history } }) {

  const data = {email: user.email, password: user.password}
  try {
    const response = yield call(loginAxios, data)

    if (response.success){
      let token = response.auth_token;
      yield put(loginSuccess(response.user))
      localStorage.setItem("authUser", JSON.stringify(response.user))
      localStorage.setItem("authToken", response.auth_token)
      localStorage.setItem("refreshToken", response.refresh_token)
      history('/dashboard');
    }else{
      yield put(apiError(response.message))
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
    // Afficher un message d'erreur à l'utilisateur
    yield put(apiError("Une erreur s'est produite lors de la connexion. Veuillez réessayer."));
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    logoutService()
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history("/dashboard");
      } else {
        history("/login");
      }
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    if(response)
    history("/dashboard");
  } catch (error) {
    console.log("error",error)
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
