import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import img7 from "../../assets/images/product/img-7.png"
import img4 from "../../assets/images/product/img-4.png"

const ProductsModal = props => {
  const { isOpen, toggle } = props
  const createdDateTime = new Date(props.product?.created_date)

  const formattedDateTime = `${
    createdDateTime.getDate()
  }-${
    createdDateTime.getMonth()
  }-${createdDateTime.getFullYear()} à ${
    createdDateTime.getHours()
  }:${
    createdDateTime.getMinutes()
  }:${createdDateTime.getSeconds()}`;


  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="col-10 modal-lg"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Détails produit</ModalHeader>
        <ModalBody>
          <p className="mb-4">
            Ajouté par: <span className="text-primary">{props.product?.uploader?.prenom} {props.product?.uploader?.nom}</span>
          </p>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Nom:</h6>
                  </td>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">{props.product?.title}</h5>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Prix:</h6>
                  </td>
                  <td>
                    <div>
                    <td>{props.product?.price} XOF</td>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Vendus:</h6>
                  </td>
                  <td>
                    <div>
                      <td>{props.product?.nbrAchat} {props.product?.unity?.libelle}</td>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Date d'ajout:</h6>
                  </td>
                  <td>
                    {formattedDateTime}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Vendeur(se):</h6>
                  </td>
                  <td>
                    <div>
                      <td>{props.product?.uploader?.first_name} {props.product?.uploader?.last_name}</td>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Prix d'achat en gros:</h6>
                  </td>
                  <td>
                    <div>
                    <td>{props.product?.prix_gros} XOF</td>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Description:</h6>
                  </td>
                  <td>
                    <pr>{props.product?.description || "Pas de description"}</pr>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-right">Image:</h6>
                  </td>
                  <td colSpan="2">
                  {
                    props.product?.photo? (
                      <img src={props.product?.photo} alt="" className="avatar-sm"/>
                    ):(
                      'Pas de photo'
                    )
                  }
                    
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Fermer
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

ProductsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ProductsModal
