import React from "react";
import { Navigate } from "react-router-dom";

// Pages Component
import Chat from "../pages/Chat/Chat";

// File Manager
import FileManager from "../pages/FileManager/index";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";
import EmailBasicTemplte from "../pages/Email/email-basic-templte";
import EmailAlertTemplte from "../pages/Email/email-template-alert";
import EmailTemplateBilling from "../pages/Email/email-template-billing";

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";


// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";


import NewInvoice from "pages/Invoices/new-invoice";
import Products from "pages/Products";
import Customers from "pages/Customers";

import FacturesList from "pages/Factures/factures.list";
import Fournisseurs from "pages/Fournisseurs";
import AddProduct from "pages/Products/AddProduct";
import UsersList from "pages/users/users-list";
import EditProduct from "pages/Products/EditProduct";
import UsersProfile from "pages/users/UsersProfile/users-profile";
import FiltredProducts from "pages/Products/Filtred";
import FournisseursProfile from "pages/Fournisseurs/FournisseursProfile/Fournisseurs-profile";
import ClientsProfile from "pages/Customers/ClientsProfile/Clients-profile";
import NewEstimation from "pages/Estimation/new-estimation";
import DevisList from "pages/Estimation/devis-list"; 
import EstimationDetail from "pages/Estimation/devis-detail"; 


const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //chat
  { path: "/chat", component: <Chat /> },

  //File Manager
  { path: "/apps-filemanager", component: <FileManager /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Ecommerce
  { path: "/products", component: <Products /> },
  { path: "/produit/ajout", component: <AddProduct /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  //Invoices
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/facture/:id", component: <InvoiceDetail /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },
  { path: "/new-invoice", component: <NewInvoice /> },

  //devis
  { path: "/new-estimation", component: <NewEstimation /> },
  { path: "/devis", component: <DevisList /> },
  { path: "/devis/:id", component: <EstimationDetail /> },




  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "/clients", component: <Customers /> },
  { path: "/factures", component: <FacturesList /> },
  { path: "/fournisseurs", component: <Fournisseurs /> },
  { path: "/employes", component: <UsersList /> },
  { path: "/products/edit/:id", component: <EditProduct /> },
  { path: "/products/filtred/:infos", component: <FiltredProducts /> },
  { path: "users-profile/:id", component: <UsersProfile /> },
  { path: "fournisseurs/profile/:id", component: <FournisseursProfile /> },
  { path: "clients/profile/:id", component: <ClientsProfile /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "*", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
];

export { authProtectedRoutes, publicRoutes };
