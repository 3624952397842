import PropTypes from "prop-types";
import React, {useEffect} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { post } from "helpers/apiServices";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

// action

import bditoffical from 'assets/images/bditoffical.png';

const ForgetPasswordPage = props => {

  //meta title
  document.title = "Mot de passe oublié | BD International Trading";
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      if (authUser) {
        
        navigate("/dashboard");
      }
    }
  }, [props.success]);



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      
      try {
        const response = await post('authentication/password/forget/', {'email': values.email});
        console.log(response);
        if(response.status === 200){
          toast.success(response?.data?.details)
        }
        
      } catch (error) {
        if(error?.response?.status === 403 || error?.response?.status === 404){
          
          toast.error(error?.response?.data?.details)
        }else{
          toast.error('Une erreur est survenue lors de la création de l\'utilisateur')
          console.error('Error', error);
        }
        // Gérer l'erreur ici
      }
      
    }
  });


  const selectForgotPasswordState = (state) => state.ForgetPassword;
    const ForgotPasswordProperties = createSelector(
      selectForgotPasswordState,
        (forgetPassword) => ({
          forgetError: forgetPassword.forgetError,
          forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
        })
    );

    const {
      forgetError,
      forgetSuccessMsg
  } = useSelector(ForgotPasswordProperties);    

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-12">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Bienvenue !</h5>
                        <p>Merci de renseigner votre email pour recevoir un nouveau mot de passe.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                              src={bditoffical}
                              alt=""
                              className="rounded-circle"
                              height="90"
                            />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Saisir email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Soumettre
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Retourner à la page de connexion{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                
                <p>
                  © {new Date().getFullYear()} BD Internatinal Trading
                  {/* <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
